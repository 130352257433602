.thumbnail {
    position: relative;
    opacity:0.75
}

.shimLogo {
  height: 150px;
}

.caption {
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
}

.hovereffect:hover {
  opacity:1
}
